import { deviceInterceptor } from './device/device.interceptor';
import { errorInterceptor } from './error/error.interceptor';
import { tokenInterceptor } from './token/token.interceptor';
import { tokenRefreshInterceptor } from './token-refresh/token-refresh.interceptor';
import { versionInterceptor } from './version/version.interceptor';

export const httpInterceptorProviders = [
  deviceInterceptor,
  errorInterceptor,
  tokenInterceptor,
  tokenRefreshInterceptor,
  versionInterceptor
];

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs';

import { QuestInstanceService } from '../quest-instance/quest-instance.service';

@Injectable({
  providedIn: 'root'
})
export class RouterNavigationService {

  constructor(private router: Router,
              private questInstanceService: QuestInstanceService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((navigationEnd) => {
     this.questInstanceService.getCurrentQuestInstance()
       .pipe(first())
       .subscribe((questInstance) => {
         if (questInstance && !navigationEnd.urlAfterRedirects.startsWith(`/projects/${questInstance.id}`)) {
           this.questInstanceService.setCurrentQuestInstance(undefined);
         }
       });
    });
  }
}

import { Injectable } from '@angular/core';

import { Browser } from '@capacitor/browser';

type PresentationStyle = 'fullscreen' | 'popover';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {

  async open(url: string, presentationStyle: PresentationStyle = 'popover'): Promise<void> {
    await Browser.open({ url, presentationStyle });
  }
}

import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { provideIonicAngular } from '@ionic/angular/standalone';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { AppInitService, RouterNavigationService } from '../services';
import { httpInterceptorProviders } from '../interceptors';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

export const initializeApp = (appInitService: AppInitService): () => Promise<void> => (): Promise<void> => appInitService.init();

export const appSharedConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular({ mode: 'ios', innerHTMLTemplatesEnabled: true }),
    importProvidersFrom(IonicStorageModule.forRoot({
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
    })),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })),
    provideHttpClient(withInterceptors(httpInterceptorProviders)),
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService, RouterNavigationService], multi: true },
  ]
};

import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first } from 'rxjs';

import { QuestInstanceService } from '../../services';

export const keyTemplatesResolver: ResolveFn<boolean> = (route, state) => {
  const router: Router = inject(Router);
  const questInstanceService: QuestInstanceService = inject(QuestInstanceService);
  const extrasState = router.getCurrentNavigation()?.extras.state;

  const keyTemplates = extrasState?.['keyTemplates'];
  const questInstanceId = route.parent?.parent?.paramMap.get('questInstanceId');
  const seriesTemplateId = route.queryParamMap.get('seriesTemplateId');

  return keyTemplates ? keyTemplates :
    (questInstanceId && seriesTemplateId ? questInstanceService.getQuestInstanceKeyTemplatesBySeriesTemplateId(questInstanceId, seriesTemplateId).pipe(first()) : null);
};

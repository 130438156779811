import { Injectable } from '@angular/core';
import { BehaviorSubject, first, forkJoin, Observable } from 'rxjs';

import { Comment, MediaFile, MessageInputEditData } from '../../models';
import { Functions } from '../../utils';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class CommentEditionService {

  protected messageInputEditData: BehaviorSubject<MessageInputEditData | undefined> = new BehaviorSubject<MessageInputEditData | undefined>(undefined);

  constructor(private fileService: FileService) {
  }

  getMessageInputEditData(): Observable<MessageInputEditData | undefined> {
    return this.messageInputEditData.asObservable();
  }

  setMessageInputEditData(messageInputEditData: MessageInputEditData): void {
    this.messageInputEditData.next(messageInputEditData);
  }

  unsetMessageInputEditData(): void {
    this.messageInputEditData.next(undefined);
  }

  prepareCommentEdition(comment: Comment): void {
    const messageInputEditData: MessageInputEditData = {
      object: comment,
      text: comment.text
    };

    if (comment.links.length === 0) {
      this.setMessageInputEditData(messageInputEditData);
      return;
    }

    forkJoin(comment.links.map(link => this.fileService.getFileByUrl(link)))
      .pipe(first())
      .subscribe(blobResponses => {
        const mediaFiles: MediaFile[] = [];
        blobResponses.forEach((response, index) => {
          mediaFiles.push(Functions.blobResponseToMediaFile(response,
            comment.links[index].split('?')[0].split('/').pop() as string, comment.links[index]));
        });

        messageInputEditData.mediaFiles = mediaFiles;
        this.setMessageInputEditData(messageInputEditData);
      });
  }
}

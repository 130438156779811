import { Injectable, Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import { first } from 'rxjs';

import 'moment/locale/fr';
import 'moment/locale/pt-br';

import { TranslateService } from '@ngx-translate/core';

import { App } from '@capacitor/app';

import { environment } from '../../environments';
import { AuthService } from '../auth/auth.service';
import { PaletteThemeService } from '../palette-theme/palette-theme.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  version = ''

  constructor(private injector: Injector,
              private translateService: TranslateService,
              private paletteThemeService: PaletteThemeService,
              private userService: UserService) {
    this.registerTranslateModuleLanguages();
  }

  registerTranslateModuleLanguages(): void {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localePt, 'pt-BR');
    registerLocaleData(localePt, 'pt-MZ');

    this.translateService.addLangs(environment.locales);
  }

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.paletteThemeService.init();
      this.setAppVersion();
      this.setDefaultLanguage();

      const authService: AuthService = this.injector.get(AuthService);
      authService.isLoggedIn().then((authenticated) => {
        if (authenticated) {
          this.userService
            .getCurrentUser()
            .pipe(first())
            .subscribe({
              next: user => {
                authService.setAuthenticatedUser(user);
                resolve();
              },
              error: err => {
                resolve(err);
              }
            });
        } else {
          resolve();
        }
      });
    });
  }

  setAppVersion(): void {
    App.getInfo().then(info => {
      this.version = info.version;
    }).catch(() => {
      // App not available, desktop browser
      this.version = environment.version;
    });
  }

  setDefaultLanguage(): void {
    const lang: string | undefined = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang((lang && (environment.locales.indexOf(lang) !== -1) ? lang : 'en'));
    this.translateService.use(this.translateService.getDefaultLang());
  }
}

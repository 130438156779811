import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private mStorage: Storage | null = null;

  constructor(private storage: Storage) {
  }

  async init(): Promise<void> {
    if (this.mStorage) {
      return;
    }

    await this.storage.defineDriver(CordovaSQLiteDriver);
    this.mStorage = await this.storage.create();
  }

  async get(key: string): Promise<any> {
    await this.init();
    return this.mStorage?.get(key);
  }

  async set(key: string, value: any): Promise<any> {
    await this.init();
    return this.mStorage?.set(key, value);
  }

  async remove(key: string): Promise<any> {
    await this.init();
    return this.mStorage?.remove(key);
  }

  async keys(): Promise<any> {
    await this.init();
    return this.mStorage?.keys();
  }
}

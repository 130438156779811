type BoardingSwiperSlideName = 'QUEST_INSTANCE' | 'SUPPORT' | 'PATHWAY' | 'TIMELINE';

export class BoardingSwiperSlide {
  name: BoardingSwiperSlideName;
  title: string;
  description: string;
  last: boolean;

  constructor(name: BoardingSwiperSlideName, title: string, description: string, last = false) {
    this.name = name;
    this.title = title;
    this.description = description;
    this.last = last;
  }
}

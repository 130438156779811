import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { Camera, CameraResultType, CameraSource, PermissionStatus } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';

import { FilePicker } from '@capawesome/capacitor-file-picker';

import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class CapacitiesService {

  isMobile = false;

  constructor(private platform: Platform,
              private alertService: AlertService) {
  }

  async openCamera(allowEditing = true): Promise<File | undefined> {
    return Camera.checkPermissions().then(async (permissionStatus: PermissionStatus) => {
      if (permissionStatus.camera === 'denied') {
        await this.alertService.presentPermissionToast('CAMERA');
        return undefined;
      }

      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera
      }).catch(() => undefined);

      if (image) {
        const blob = await fetch(image.dataUrl as RequestInfo)
          .then(response => response.blob());
        return new File([blob], 'camera', { type: 'image/' + image.format });
      }

      return undefined;
    });
  }

  async openGallery(limit = 9): Promise<File[] | undefined> {
    return Camera.checkPermissions().then(async (permissionStatus: PermissionStatus) => {
      if (permissionStatus.photos === 'denied') {
        await this.alertService.presentPermissionToast('GALLERY');
        return undefined;
      }

      const images = await Camera.pickImages({
        quality: 100,
        limit
      });

      const files: File[] = [];
      for (const photo of images.photos) {
        let url = '';
        if (this.platform.is('capacitor')) {
          const file = await Filesystem.readFile({
            path: photo.path as string
          });
          url = 'data:image/' + photo.format + ';base64,' + file.data;
        } else {
          url = photo.webPath;
        }

        const blob = await fetch(url as RequestInfo)
          .then(response => response.blob());
        files.push(new File([blob], 'photo', { type: 'image/' + photo.format }));
      }

      return files.length > 0 ? files : undefined;
    });
  }

  async openDocuments(): Promise<File | undefined> {
    const result = await FilePicker.pickFiles({
      limit: 1
    });

    const file = result.files[0];

    if (file.blob) {
      return new File([file.blob], file.name, { type: file.mimeType });
    } else if (file.path) {
      const readFile = await Filesystem.readFile({
        path: file.path
      });

      const binaryString = atob(readFile.data as string);
      const binaryLength = binaryString.length;
      const bytes = new Uint8Array(binaryLength);
      for (let i = 0; i < binaryLength; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: file.mimeType });
      return new File([blob], file.name, { type: file.mimeType });
    }

    return undefined;
  }
}

import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { BehaviorSubject, interval, map, Observable, startWith, Subject } from 'rxjs';

import { GenericResponse, RecordingData, VoiceRecorder } from 'capacitor-voice-recorder';

interface RecordedAudioOutput {
  blob: Blob;
  clipName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {

  private recordedAudio: Subject<RecordedAudioOutput> = new Subject<RecordedAudioOutput>();
  private recordingFailed: Subject<void> = new Subject<void>();
  private recordingTime: Observable<number> = new Observable<number>();
  private recording$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getRecordedAudio(): Observable<RecordedAudioOutput> {
    return this.recordedAudio.asObservable();
  }

  getRecordingFailed(): Observable<void> {
    return this.recordingFailed.asObservable();
  }

  getRecordedTime(): Observable<number> {
    return this.recordingTime;
  }

  requestPermission(): Promise<GenericResponse> {
    return VoiceRecorder.requestAudioRecordingPermission();
  }

  startRecording(): void {
    if (this.recording$.value) {
      return;
    }

    VoiceRecorder.startRecording()
      .then(() => {
        this.recording$.next(true);
        this.startTimer();
      })
      .catch(error => {
        this.recordingFailed.next(error);
      });
  }

  abortRecording(): void {
    if (this.recording$.value) {
      VoiceRecorder.stopRecording().then(() => {
        this.recording$.next(false);
      });
    }
  }

  stopRecording(): void {
    if (!this.recording$.value) {
      return;
    }

    VoiceRecorder.stopRecording()
      .then(async (result: RecordingData) => {
        this.recording$.next(false);
        if (result.value && result.value.recordDataBase64) {
          const data: string = result.value.recordDataBase64.startsWith('data:') ? result.value.recordDataBase64 : `data:${ result.value.mimeType };base64,${ result.value.recordDataBase64 }`;
          const clipName: string = encodeURIComponent('recording_' + formatDate(new Date(), 'yyMMdd_HHmmss', 'fr') + '.wav');

          fetch(data)
            .then(res => res.blob())
            .then(blob => {
              this.recordedAudio.next({ blob, clipName });
            });
        }
      })
      .catch(error => {
        this.recordingFailed.next(error);
      });
  }

  startTimer(): void {
    let time = 1;
    this.recordingTime = interval(1000)
      .pipe(map(() => time++), startWith(0));
  }
}

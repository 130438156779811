import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, first, Observable, ReplaySubject } from 'rxjs';

import { environment } from '../../environments';
import {
  ArticleInstance,
  Comment,
  Company,
  KeyInstance,
  KeyTemplate,
  LibraryItem,
  MeetingInstance,
  MeetingTemplate,
  MessageInput,
  Notification,
  ObjectInstanceResult,
  QuestInstance,
  QuestInstanceDetailsElement,
  QuestInstanceProject,
  QuestInstanceRole,
  SeriesTemplate,
  Share,
  TimelineItem,
  WorkshopItem,
  WorkshopItemProgress
} from '../../models';
import { Functions } from '../../utils';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../notification/notification.service';

export interface QuestInstancesResult {
  items: QuestInstanceProject[];
  total: number,
  totalNotArchived: number,
  totalArchived: number
}

@Injectable({
  providedIn: 'root'
})
export class QuestInstanceService {

  currentQuestInstance: BehaviorSubject<QuestInstance | undefined> = new BehaviorSubject<QuestInstance | undefined>(undefined);
  currentQuestInstanceRole: ReplaySubject<QuestInstanceRole> = new ReplaySubject<QuestInstanceRole>(1);
  milestoneFinished: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(private httpClient: HttpClient,
              private authService: AuthService,
              private notificationService: NotificationService) {
  }

  getCurrentQuestInstance(): Observable<QuestInstance | undefined> {
    return this.currentQuestInstance;
  }

  setCurrentQuestInstance(questInstance?: QuestInstance): void {
    this.authService.getAuthenticatedUser()
      .pipe(first())
      .subscribe(user => {
        this.setCurrentQuestInstanceRole(user.id === questInstance?.owner.id ? 'PILOT' : 'PROFESSIONAL');
      });

    this.currentQuestInstance.next(questInstance);

    if (questInstance) {
      const data = {
        limit: 1,
        filter: {
          quest: questInstance.id,
          archived: false,
          opened: false
        }
      };

      this.notificationService.getNotifications(data)
        .pipe(first())
        .subscribe(({ total }) => {
          this.notificationService.setUnreadQuestInstance(total);
        });
    } else {
      this.notificationService.setUnreadQuestInstance(0);
    }
  }

  getCurrentQuestInstanceRole(): Observable<QuestInstanceRole> {
    return this.currentQuestInstanceRole;
  }

  setCurrentQuestInstanceRole(role: QuestInstanceRole): void {
    this.currentQuestInstanceRole.next(role);
  }

  getMilestoneFinished(): Observable<boolean> {
    return this.milestoneFinished.asObservable();
  }

  setMilestoneFinished(finished: boolean): void {
    this.milestoneFinished.next(finished);
  }

  getQuestInstances(queryParams: any = {}): Observable<QuestInstancesResult> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<QuestInstancesResult>(environment.apiURL + '/quest-instances/', { params });
  }

  getQuestInstance(questInstanceId: string): Observable<QuestInstance> {
    return this.httpClient.get<QuestInstance>(environment.apiURL + '/quest-instances/' + questInstanceId);
  }

  createQuestInstance(questTemplateId: string): Observable<QuestInstance> {
    return this.httpClient.post<QuestInstance>(environment.apiURL + '/quest-instances/', { questTemplateId });
  }

  updateQuestInstance(questTemplateId: string, data: any = {}): Observable<QuestInstance> {
    return this.httpClient.put<QuestInstance>(environment.apiURL + '/quest-instances/' + questTemplateId, data);
  }

  // Get all companies from quest instances user is involved in (as pilot or professional)
  getQuestInstancesCompanies(queryParams: any = {}): Observable<Company[]> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<Company[]>(environment.apiURL + '/quest-instances/companies', { params });
  }

  /* Details */

  getQuestInstanceDetails(questInstanceId: string): Observable<QuestInstanceDetailsElement[]> {
    return this.httpClient.get<QuestInstanceDetailsElement[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/details');
  }

  /* Workshop Items */

  getQuestInstanceWorkshopItems(questInstanceId: string, queryParams: any = {}): Observable<WorkshopItem[]> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<WorkshopItem[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/workshop-items', { params });
  }

  getQuestInstanceWorkshopItemsProgress(questInstanceId: string, queryParams: any = {}): Observable<WorkshopItemProgress[]> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<WorkshopItemProgress[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/workshop-items/progress', { params });
  }

  /* Timeline Items */

  getQuestInstanceTimelineItems(questInstanceId: string, queryParams: any = {}): Observable<{
    items: TimelineItem[],
    total: number
  }> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<{
      items: TimelineItem[],
      total: number
    }>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timeline-items/toto', { params });
  }

  /* Timeline Item */

  getQuestInstanceTimelineItem(questInstanceId: string, timelineItemId: string): Observable<TimelineItem> {
    return this.httpClient.get<TimelineItem>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timeline-items/' + timelineItemId);
  }

  updateQuestInstanceTimelineItem(questInstanceId: string, timelineItemId: string, data: any = {}): Observable<TimelineItem> {
    return this.httpClient.put<TimelineItem>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timeline-items/' + timelineItemId, data);
  }

  /* Timeline Item Comment */

  createTimelineItemComment(questInstanceId: string, timelineItemId: string, data: MessageInput): Observable<Comment> {
    return this.httpClient.post<Comment>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timeline-items/' + timelineItemId + '/comments', data);
  }

  updateTimelineItemComment(questInstanceId: string, timelineItemId: string, commentId: string, data: MessageInput): Observable<Comment> {
    return this.httpClient.put<Comment>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timeline-items/' + timelineItemId + '/comments/' + commentId, data);
  }

  removeTimelineItemComment(questInstanceId: string, timelineItemId: string, commentId: string): Observable<void> {
    return this.httpClient.delete<void>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timeline-items/' + timelineItemId + '/comments/' + commentId);
  }

  /* Share */

  createQuestInstanceShare(questInstanceId: string, data: any): Observable<Share> {
    return this.httpClient.post<Share>(environment.apiURL + '/quest-instances/' + questInstanceId + '/shares', data);
  }

  updateQuestInstanceShare(questInstanceId: string, shareId: string, data: any): Observable<Share> {
    return this.httpClient.put<Share>(environment.apiURL + '/quest-instances/' + questInstanceId + '/shares/' + shareId, data);
  }

  removeQuestInstanceShare(questInstanceId: string, shareId: string): Observable<void> {
    return this.httpClient.delete<void>(environment.apiURL + '/quest-instances/' + questInstanceId + '/shares/' + shareId);
  }

  /* Timer */

  getQuestInstanceTimer(questInstanceId: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiURL + '/quest-instances/' + questInstanceId + '/timers');
  }

  /* Series & Keys */

  getQuestInstanceSeriesTemplateById(questInstanceId: string, seriesTemplateId: string): Observable<SeriesTemplate> {
    return this.httpClient.get<SeriesTemplate>(environment.apiURL + '/quest-instances/' + questInstanceId + '/series-templates/' + seriesTemplateId);
  }

  getQuestInstanceKeyTemplatesBySeriesTemplateId(questInstanceId: string, seriesTemplateId: string): Observable<KeyTemplate[]> {
    const options = { params: new HttpParams().set('seriesId', seriesTemplateId) };
    return this.httpClient.get<KeyTemplate[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/key-templates', options);
  }

  getQuestInstanceKeyInstancesBySeriesTemplateId(questInstanceId: string, seriesTemplateId: string): Observable<KeyInstance[]> {
    const options = { params: new HttpParams().set('seriesId', seriesTemplateId) };
    return this.httpClient.get<KeyInstance[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/key-instances', options);
  }

  createKeyInstance(questInstanceId: string, data: any = {}): Observable<ObjectInstanceResult> {
    return this.httpClient.post<ObjectInstanceResult>(environment.apiURL + '/quest-instances/' + questInstanceId + '/key-instances', data);
  }

  updateKeyInstance(questInstanceId: string, keyInstanceId: string, data: any = {}): Observable<KeyInstance> {
    return this.httpClient.put<KeyInstance>(environment.apiURL + '/quest-instances/' + questInstanceId + '/key-instances/' + keyInstanceId, data);
  }

  /* Article */

  createArticleInstance(questInstanceId: string, data: any = {}): Observable<ObjectInstanceResult> {
    return this.httpClient.post<ObjectInstanceResult>(environment.apiURL + '/quest-instances/' + questInstanceId + '/article-instances', data);
  }

  updateArticleInstance(questInstanceId: string, articleInstanceId: string, data: any = {}): Observable<ArticleInstance> {
    return this.httpClient.put<ArticleInstance>(environment.apiURL + '/quest-instances/' + questInstanceId + '/article-instances/' + articleInstanceId, data);
  }

  /* Library */

  getQuestInstanceLibraryItems(questInstanceId: string, queryParams: any = {}): Observable<LibraryItem[]> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<LibraryItem[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/library', { params });
  }

  /* Mood */

  createMoodInstance(questInstanceId: string, data: any = {}): Observable<ObjectInstanceResult> {
    return this.httpClient.post<ObjectInstanceResult>(environment.apiURL + '/quest-instances/' + questInstanceId + '/mood-instances', data);
  }

  /* Meetings */

  getQuestInstanceMeetingTemplates(questInstanceId: string): Observable<MeetingTemplate[]> {
    return this.httpClient.get<MeetingTemplate[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-templates');
  }

  getQuestInstanceMeetingInstances(questInstanceId: string): Observable<MeetingInstance[]> {
    return this.httpClient.get<MeetingInstance[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances');
  }

  createQuestInstanceMeetingInstance(questInstanceId: string, data: any = {}): Observable<MeetingInstance> {
    return this.httpClient.post<MeetingInstance>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances', data);
  }

  updateQuestInstanceMeetingInstance(questInstanceId: string, meetingInstanceId: string, data: any = {}): Observable<MeetingInstance> {
    return this.httpClient.put<MeetingInstance>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances/' + meetingInstanceId, data);
  }

  getQuestInstanceMeetingInstance(questInstanceId: string, meetingInstanceId: string): Observable<MeetingInstance> {
    return this.httpClient.get<MeetingInstance>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances/' + meetingInstanceId);
  }

  getMeetingInstanceComments(questInstanceId: string, meetingInstanceId: string, queryParams: any = {}): Observable<{
    items: Comment[],
    total: number
  }> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<{
      items: Comment[],
      total: number
    }>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances/'
      + meetingInstanceId + '/comments', { params });
  }

  createMeetingInstanceComment(questInstanceId: string, meetingInstanceId: string, data: MessageInput): Observable<Comment> {
    return this.httpClient.post<Comment>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances/'
      + meetingInstanceId + '/comments', data);
  }

  updateMeetingInstanceComment(questInstanceId: string, meetingInstanceId: string, commentId: string,
                               data: MessageInput): Observable<Comment> {
    return this.httpClient.put<Comment>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances/'
      + meetingInstanceId + '/comments/' + commentId, data);
  }

  removeMeetingInstanceComment(questInstanceId: string, meetingInstanceId: string, commentId: string): Observable<void> {
    return this.httpClient.delete<void>(environment.apiURL + '/quest-instances/' + questInstanceId + '/meeting-instances/'
      + meetingInstanceId + '/comments/' + commentId);
  }

  /* Notifications */

  getQuestInstanceNotifications(questInstanceId: string, queryParams: any = {}): Observable<Notification[]> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<Notification[]>(environment.apiURL + '/quest-instances/' + questInstanceId + '/notifications', { params });
  }

  /* TEMPORARY */

  getQuestInstanceStats(questInstanceId: string): Observable<any> {
    return this.httpClient.get<any>(environment.apiURL + '/quest-instances/' + questInstanceId + '/stats');
  }
}

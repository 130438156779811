import { HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';

import { from, of, switchMap } from 'rxjs';

import { LocalStorageService } from '../../services';

export const tokenRefreshInterceptor: HttpInterceptorFn = (req, next) => {
  const localStorageService: LocalStorageService = inject(LocalStorageService);

  return next(req).pipe(
    switchMap((res: any) => {
      if (res instanceof HttpResponse && res.headers.get('Refresh-Token')) {
        return from(localStorageService.set('token', res.headers.get('Refresh-Token'))).pipe(
          switchMap(() => of(res))
        );
      } else {
        return of(res);
      }
    })
  );
};

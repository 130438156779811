import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments';
import { Policy } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(private httpClient: HttpClient) {
  }

  getLatest(queryParams: any = {}): Observable<Policy> {
    const options = { params: new HttpParams() };

    if (queryParams) {
      for (const [key, value] of Object.entries(queryParams)) {
        options.params = options.params.append(key, String(value));
      }
    }

    return this.httpClient.get<Policy>(environment.apiURL + '/policies/latest', options);
  }

}

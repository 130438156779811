export class FormNameValue {
  public name: string;
  public value: any;
  public color: string;

  constructor(name: string, value: any, color = '#000') {
    this.name = name;
    this.value = value;
    this.color = color;
  }
}

export class FormNameIconValue extends FormNameValue {
  public icon: string;

  constructor(name: string, value: any, color = '#000', icon: string) {
    super(name, value, color);
    this.icon = icon;
  }
}

export class FormValues {
  public static readonly moods = [
    new FormNameIconValue('MOOD_EXCITED', 4, '#000', '😆'),
    new FormNameIconValue('MOOD_CHEERFUL', 3, '#000', '😄'),
    new FormNameIconValue('MOOD_ZEN', 2, '#000', '🙂'),
    new FormNameIconValue('MOOD_DISORIENTED', 1, '#000', '😞'),
    new FormNameIconValue('MOOD_DISSATISFIED', 0, '#000', '😠')
  ];

  public static readonly currencies = [
    new FormNameIconValue('CURRENCY_EURO', 'EUR', '#000', '€'),
    new FormNameIconValue('CURRENCY_CANADIAN_DOLLAR', 'CAD', '#000', 'CA$'),
    new FormNameIconValue('CURRENCY_DOLLAR', 'USD', '#000', '$'),
    new FormNameIconValue('CURRENCY_POUND', 'GBP', '#000', '£')
  ];
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments';
import { QuestTemplate } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class QuestTemplateService {

  constructor(private httpClient: HttpClient) {
  }

  getAssociatedQuestTemplates(): Observable<QuestTemplate[]> {
    return this.httpClient.get<QuestTemplate[]>(environment.apiURL + '/quest-templates/association');
  }
}

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AlertController, ToastController } from '@ionic/angular';

import { addIcons } from 'ionicons';
import { alertCircleOutline, earthOutline } from 'ionicons/icons';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private alertController: AlertController,
              private toastController: ToastController,
              private translateService: TranslateService) {
    addIcons({
      alertCircleOutline,
      earthOutline
    });
  }

  async presentForbiddenHttpRequestAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('ALERT_FORBIDDEN_HTTP_REQUEST_HEADER'),
      message: this.translateService.instant('ALERT_FORBIDDEN_HTTP_REQUEST_MESSAGE'),
      buttons: [this.translateService.instant('BTN_OK')]
    });

    await alert.present();
  }

  async presentAccountDisabledAlert() {
    const alert = await this.alertController.create({
      header: this.translateService.instant('ALERT_ACCOUNT_DISABLED_HEADER'),
      message: this.translateService.instant('ALERT_ACCOUNT_DISABLED_MESSAGE'),
      buttons: [{
        text: this.translateService.instant('BTN_OK')
      }]
    });

    await alert.present();
  }

  async presentToast(message: string, cssClass = '', duration = 1500, icon?: string) {
    const toast = await this.toastController.create({
      message,
      cssClass,
      duration,
      position: 'top',
      icon
    });

    await toast.present();
  }

  async presentPermissionToast(input = 'CAMERA') {
    const toast = await this.toastController.create({
      message: this.translateService.instant('PERMISSION_MESSAGE_' + input),
      cssClass: 'warning-toast',
      duration: 1500,
      position: 'top',
      icon: 'alert-circle-outline'
    });

    await toast.present();
  }
}

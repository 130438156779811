import { isPlatformServer } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, NgModule } from '@angular/core';
import { Storage } from '@ionic/storage';
export { Storage } from '@ionic/storage';
const StorageConfigToken = new InjectionToken('STORAGE_CONFIG_TOKEN');
class NoopStorage extends Storage {
  constructor() {
    super();
  }
  async create() {
    return this;
  }
  async defineDriver() {}
  get driver() {
    return 'noop';
  }
  async get(key) {
    return null;
  }
  async set(key, value) {}
  async remove(key) {}
  async clear() {}
  async length() {
    return 0;
  }
  async keys() {
    return [];
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  async forEach(iteratorCallback) {}
  setEncryptionKey(key) {}
}
function provideStorage(platformId, storageConfig) {
  if (isPlatformServer(platformId)) {
    // When running in a server context return the NoopStorage
    return new NoopStorage();
  }
  return new Storage(storageConfig);
}
class IonicStorageModule {
  static forRoot(storageConfig = null) {
    return {
      ngModule: IonicStorageModule,
      providers: [{
        provide: StorageConfigToken,
        useValue: storageConfig
      }, {
        provide: Storage,
        useFactory: provideStorage,
        deps: [PLATFORM_ID, StorageConfigToken]
      }]
    };
  }
}
IonicStorageModule.ɵfac = function IonicStorageModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IonicStorageModule)();
};
IonicStorageModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: IonicStorageModule
});
IonicStorageModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IonicStorageModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IonicStorageModule, StorageConfigToken, provideStorage };

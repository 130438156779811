import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { AppInitService } from '../../services/app-init/app-init.service';

export const versionInterceptor: HttpInterceptorFn = (req, next) => {
  const appInitService= inject(AppInitService);

  return next(req.clone({
    setHeaders: {
      'app-version': appInitService.version
    }
  }));
};

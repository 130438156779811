import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { saveAs } from 'file-saver';

import { MediaFile } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient: HttpClient) {
  }

  getFileByUrl(url: string): Observable<any> {
    const headers = new HttpHeaders({
      Range: 'bytes=0-1'
    });

    return this.httpClient.get(url, { headers, observe: 'response', responseType: 'blob' });
  }

  downloadMediaFile(mediaFile: MediaFile): void {
    fetch(mediaFile.link)
      .then(res => res.blob())
      .then((blob: Blob) => {
        saveAs(blob, mediaFile.fileName);
      });
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments';
import { ProfessionalBill } from '../../models';
import { Functions } from '../../utils';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalBillService {

  constructor(private httpClient: HttpClient) {
  }

  getProfessionalBills(queryParams: any = {}): Observable<{ items: ProfessionalBill[], total: number }> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);

    return this.httpClient.get<{
      items: ProfessionalBill[],
      total: number
    }>(environment.apiURL + '/professionals/bills/', { params: params });
  }

  createProfessionalBill(professionalBill: any): Observable<ProfessionalBill> {
    return this.httpClient.post<ProfessionalBill>(environment.apiURL + '/professionals/bills', professionalBill);
  }

  updateProfessionalBill(professionalBillId: string, data: any): Observable<ProfessionalBill> {
    return this.httpClient.put<ProfessionalBill>(environment.apiURL + '/professionals/bills/' + professionalBillId, data);
  }
}

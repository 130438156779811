import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first } from 'rxjs';

import { QuestInstanceService } from '../../services';

export const seriesTemplateResolver: ResolveFn<boolean> = (route, state) => {
  const router: Router = inject(Router);
  const questInstanceService: QuestInstanceService = inject(QuestInstanceService);
  const extrasState = router.getCurrentNavigation()?.extras.state;

  const seriesTemplate = extrasState?.['seriesTemplate'];
  const questInstanceId = route.parent?.parent?.paramMap.get('questInstanceId');
  const seriesTemplateId = route.queryParamMap.get('seriesTemplateId');

  return seriesTemplate ? seriesTemplate :
    (questInstanceId && seriesTemplateId ? questInstanceService.getQuestInstanceSeriesTemplateById(questInstanceId, seriesTemplateId).pipe(first()) : null);
};

import { EventEmitter, Injectable } from '@angular/core';

import { io, Socket } from 'socket.io-client';

import { environment } from '../../environments';
import { LocalStorageService } from '../local-storage/local-storage.service';
import {
  SocketEventsResponse,
  SocketMeetingCommentResponse,
  SocketMeetingResponse,
  SocketNotificationsResponse,
  SocketQuestInstanceProgressResponse,
  SocketTimelineResponse,
  SocketWorkshopItemsProgressResponse
} from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SocketIOService {

  socket?: Socket;
  token?: string;

  meeting: EventEmitter<SocketMeetingResponse> = new EventEmitter<SocketMeetingResponse>();
  meetingComment: EventEmitter<SocketMeetingCommentResponse> = new EventEmitter<SocketMeetingCommentResponse>();
  notifications: EventEmitter<SocketNotificationsResponse> = new EventEmitter<SocketNotificationsResponse>();
  questInstanceProgress: EventEmitter<SocketQuestInstanceProgressResponse> = new EventEmitter<SocketQuestInstanceProgressResponse>();
  timelineItem: EventEmitter<SocketTimelineResponse> = new EventEmitter<SocketTimelineResponse>();
  workshopItemsProgress: EventEmitter<SocketWorkshopItemsProgressResponse> = new EventEmitter<SocketWorkshopItemsProgressResponse>();

  constructor(private localStorageService: LocalStorageService) {
    this.initSocketConnection();
  }

  initSocketConnection(): void {
    this.socket = io(environment.socketIO, {
      autoConnect: false
    });

    this.socket.on('events', (response: SocketEventsResponse) => {
      switch (response.objectType) {
        case 'Comment':
          if (['MEETING_ADD_COMMENT', 'MEETING_MODIFY_COMMENT', 'MEETING_REMOVE_COMMENT'].includes(response.type)) {
            this.meetingComment.emit(response as SocketMeetingCommentResponse);
          }
          break;
        case 'MeetingInstance':
          this.meeting.emit(response as SocketMeetingResponse);
          break;
        case 'TimelineItem':
          this.timelineItem.emit(response as SocketTimelineResponse);
          break;
      }
    });

    this.socket.on('notifications', (response: SocketNotificationsResponse) => {
      this.notifications.emit(response);
    });

    this.socket.on('quest-instance-progress', (response: SocketQuestInstanceProgressResponse) => {
      this.questInstanceProgress.emit(response);
    });

    this.socket.on('workshop-items-progress', (response: SocketWorkshopItemsProgressResponse) => {
      this.workshopItemsProgress.emit(response);
    });

    this.socket.on('connect', () => {
      this.authenticate();
    });

    this.socket.on('error', (message: any) => {
      console.log('socket.io.error', message);
    });
  }

  authenticate(): void {
    if (this.token) {
      this.socket?.emit('authenticate', this.token);
    }
  }

  async connect(): Promise<void> {
    this.token = await this.localStorageService.get('token');
    this.socket?.connect();
  }

  disconnect(): void {
    this.token = undefined;
    this.socket?.disconnect();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Professional } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class SignUpDataService {

  step: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  totalSteps = 3;
  submitting = false;
  exists = false;
  isSSO = false;

  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  avatar?: string;
  language = 'en';
  professional?: Professional;
  timezone?: string;

  isValid(): boolean {
    return !(!this.email || !this.firstName || !this.lastName || (!this.isSSO && !this.password) || !this.timezone);
  }

  getStep(): Observable<number> {
    return this.step;
  }

  getData() {
    return {
      email: this.email,
      ...(this.password) && { password: this.password },
      firstName: this.firstName,
      lastName: this.lastName,
      language: this.language,
      timezone: this.timezone,
      ...(this.avatar) && { avatar: this.avatar },
      confirmed: true
    };
  }
}

import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { Platform } from '@ionic/angular';

export const deviceInterceptor: HttpInterceptorFn = (req, next) => {
  const platform: Platform = inject(Platform);

  const hasCapacitor: boolean = platform.is('capacitor');

  if (hasCapacitor) {
    return next(req.clone({
      setHeaders: {
        'capacitor': 'true'
      }
    }));
  } else {
    return next(req);
  }
};

import { Injectable } from '@angular/core';
import { take } from 'rxjs';

import { Platform } from '@ionic/angular/standalone';

import { SplashScreen } from '@capacitor/splash-screen';

import { AuthService } from '../auth/auth.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppReloadService {

  threshold = 10 * 60 * 1000; // 10 minutes

  constructor(private platform: Platform,
              private authService: AuthService,
              private localStorageService: LocalStorageService) {
  }

  init(): void {
    this.platform.pause.subscribe(async () => {
      await this.localStorageService.set('reload', Date.now());
    });

    this.platform.resume.subscribe(async () => {
      const lastReload = await this.localStorageService.get('reload');
      if (Date.now() - lastReload >= this.threshold) {
        location.reload();
        await SplashScreen.show();
      } else {
        this.authService.getAuthenticatedUser().pipe(take(1)).subscribe(user => {
          if (user) {
            this.authService.getAndSetUnreadTotalNotifications();
          }
        });
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments';
import { S3SignedUrl } from '../../models';

import heic2any from 'heic2any';

@Injectable({
  providedIn: 'root'
})
export class UploadS3Service {

  constructor(private httpClient: HttpClient) {
  }

  getSignedUrl(name: string, type: string): Observable<S3SignedUrl> {
    const params: HttpParams = new HttpParams()
      .set('fileName', name)
      .set('fileType', type);

    return this.httpClient.get<any>(environment.apiURL + '/s3/sign', { params });
  }

  uploadFile(signedUrl: string, file: File): Observable<any> {
    return this.httpClient.put<any>(signedUrl, file, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getHeicToJpg(url: string): Observable<Blob> {
    return new Observable<Blob>((observer) => {
      this.httpClient.get(url, { responseType: 'blob' }).subscribe(async (blob: Blob) => {
        try {
          const jpegBlob = await heic2any({ blob, toType: 'image/jpeg', quality: 0.7 });
          observer.next(jpegBlob instanceof Blob ? jpegBlob : jpegBlob[0]);
          observer.complete();
        } catch (error) {
          observer.error(error);
        }
      });
    });
  }

  blobHeicToJpg(blob: Blob): Observable<Blob> {
    return new Observable<Blob>((observer) => {
        heic2any({ blob, toType: 'image/jpeg', quality: 0.7 })
          .then((jpegBlob) => {
            observer.next(jpegBlob instanceof Blob ? jpegBlob : jpegBlob[0]);
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      });
  }
}

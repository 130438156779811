import { ResolveFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { first } from 'rxjs';

import { QuestInstanceService } from '../../services';

export const questInstanceResolver: ResolveFn<boolean> = (route, state) => {
  const router: Router = inject(Router);
  const questInstanceService: QuestInstanceService = inject(QuestInstanceService);
  const extrasState = router.getCurrentNavigation()?.extras.state;

  const questInstance = extrasState?.['questInstance'];
  const questInstanceId = route.paramMap.get('questInstanceId');

  return questInstance && questInstance.id === questInstanceId ? questInstance :
    (questInstanceId ? questInstanceService.getQuestInstance(questInstanceId).pipe(first()) : null);
};

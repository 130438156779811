import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';

export function multipleChoicesValidator(min: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const checked = (control as FormArray).controls.filter(x => x.value === true);

    if (checked.length >= min) {
      return {};
    }

    return { error: true };
  };
}

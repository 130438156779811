import { Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, first, Observable, Subject, takeUntil } from 'rxjs';

import { initializeApp, FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, Messaging } from 'firebase/messaging';

import { Platform } from '@ionic/angular';

import { PermissionStatus, PushNotifications, Token } from '@capacitor/push-notifications';

import { environment } from '../../environments';
import { Notification, SocketNotificationsResponse } from '../../models';
import { Functions } from '../../utils';
import { BadgeService } from '../badge/badge.service';
import { QuestInstanceService } from '../quest-instance/quest-instance.service';
import { SocketIOService } from '../socket-io/socket-io.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  unreadQuestInstance: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  unreadTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  unsubscribe$: Subject<void> = new Subject<void>();

  private firebaseApp?: FirebaseApp;
  private firebaseMessaging?: Messaging;
  private deviceToken?: string;
  private isMobile?: boolean;

  constructor(private httpClient: HttpClient,
              private injector: Injector,
              private platform: Platform,
              private badgeService: BadgeService,
              private socketIOService: SocketIOService,
              private userService: UserService) {

    this.socketIOService.notifications
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: SocketNotificationsResponse) => {
        this.unreadTotal.next(response.numberUnreadNotifications);
        if (this.platform.is('capacitor')) {
          this.badgeService.set(response.numberUnreadNotifications);
        }

        const questInstanceService = injector.get(QuestInstanceService);
        questInstanceService.getCurrentQuestInstance()
          .pipe(first())
          .subscribe(questInstance => {
            if (questInstance) {
              const questInstanceResponse = response.questInstances.find(questInstanceResponse => questInstanceResponse.questInstanceId === questInstance.id);
              if (questInstanceResponse) {
                this.unreadQuestInstance.next(questInstanceResponse.numberUnreadNotifications);
              } else {
                this.unreadQuestInstance.next(0);
              }
            }
          });
      });

    this.isMobile = this.platform.is('capacitor');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUnreadQuestInstance(): Observable<number> {
    return this.unreadQuestInstance.asObservable();
  }

  setUnreadQuestInstance(value: number): void {
    this.unreadQuestInstance.next(value);
  }

  getUnreadTotal(): Observable<number> {
    return this.unreadTotal.asObservable();
  }

  setUnreadTotal(value: number): void {
    this.unreadTotal.next(value);
  }

  getNotifications(queryParams: any = {}): Observable<{ items: Notification[], total: number }> {
    const params: HttpParams = Functions.objectQueryToHttpParams(queryParams);
    return this.httpClient.get<{
      items: Notification[],
      total: number
    }>(environment.apiURL + '/notifications', { params });
  }

  openNotifications(data: any = {}): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/open', data);
  }

  archiveNotifications(data: any = {}): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/archive', data);
  }

  deleteNotifications(data: any = {}): Observable<any> {
    return this.httpClient.delete<any[]>(environment.apiURL + '/notifications', { params: data });
  }

  // Not use anymore because all notifications of an object should be opened
  openNotification(notificationId: string): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/' + notificationId + '/open', {});
  }

  archiveNotification(notificationId: string, data: { archive: boolean }): Observable<any> {
    return this.httpClient.put<any[]>(environment.apiURL + '/notifications/' + notificationId + '/archive', data);
  }

  deleteNotification(notificationId: string): Observable<any> {
    return this.httpClient.delete<any[]>(environment.apiURL + '/notifications/' + notificationId);
  }

  setMobilePushNotifications(): void {
    PushNotifications.requestPermissions().then((permissionStatus: PermissionStatus) => {
      if (permissionStatus.receive === 'granted') {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      this.userService.addDeviceToken(token.value, true).subscribe(() => {
        this.deviceToken = token.value;
      });
    });

    // PushNotifications.addListener('registrationError',(error: any) => {});

    PushNotifications.addListener('pushNotificationReceived', () => {
      // Notification received on opened app
    });

    PushNotifications.addListener('pushNotificationActionPerformed', () => {
      // Click on notification
    });

    if (this.deviceToken) {
      this.userService.addDeviceToken(this.deviceToken, true).subscribe(() => {
        // Do nothing
      });
    }
  }

  setDesktopPushNotifications(): void {
    if ('serviceWorker' in navigator && navigator.serviceWorker) {
      if (!this.firebaseApp) {
        this.firebaseApp = initializeApp(environment.firebase);
      }

      if (!this.firebaseMessaging) {
        this.firebaseMessaging = getMessaging(this.firebaseApp);
      }

      if (this.deviceToken) {
        this.userService.addDeviceToken(this.deviceToken, false).subscribe(() => {
          // added
        });
      } else {
        getToken(this.firebaseMessaging, { vapidKey: environment.firebaseVapidKey }).then((currentToken) => {
          if (currentToken) {
            this.userService.addDeviceToken(currentToken, false).subscribe(() => {
              this.deviceToken = currentToken;
            });
          }
        }).catch(() => {
          // error
        });
      }

      onMessage(this.firebaseMessaging, (payload) => {
        // on message
      });
    }
  }

  removeDeviceRegistration(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.deviceToken) {
        //this.badge.clear();
        this.userService.removeDeviceToken(this.deviceToken, !!this.isMobile).subscribe({
          next: () => {
            resolve();
          },
          error: (error) => {
            reject(error);
          }
        });
      } else {
        resolve();
      }
    });
  }
}

import { Injectable } from '@angular/core';

import { LocalStorageService } from '../local-storage/local-storage.service';

export type AppearanceMode = 'light' | 'dark' | 'system';

@Injectable({
  providedIn: 'root'
})
export class PaletteThemeService {

  appearanceMode: AppearanceMode = 'system';

  constructor(private localStorageService: LocalStorageService) {
  }

  init(): void {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.addEventListener('change', () => this.setPaletteTheme());
    this.setPaletteTheme();
  }

  setPaletteTheme(): void {
    this.localStorageService.get('appearance_mode')
      .then(mode => {
        this.appearanceMode = mode || 'system';
        this.applyTheme();
      });
  }

  private applyTheme(): void {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const shouldAddDarkClass = this.appearanceMode === 'dark' || (this.appearanceMode === 'system' && prefersDark);
    document.documentElement.classList.toggle('ion-palette-dark', shouldAddDarkClass);
  }
}

import { Injectable } from '@angular/core';

import { Badge } from '@capawesome/capacitor-badge';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  constructor() {
  }

  set = async (count: number) => {
    await Badge.set({ count });
  };

  clear = async () => {
    await Badge.clear();
  };
}

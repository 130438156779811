import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from '@angular/router';

@Injectable()

export class CustomRouteReuseStrategy implements BaseRouteReuseStrategy {
  private routeStore: Map<string, DetachedRouteHandle> = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // console.log('CustomRouteReuseStrategy shouldDetach', !!route.routeConfig && !!route.data['reuse']);
    return !!route.routeConfig && !!route.data['reuse'];
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // console.log('CustomRouteReuseStrategy store', route, handle);
    this.routeStore.set(route.routeConfig?.path as string, handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // console.log('CustomRouteReuseStrategy shouldAttach', route);
    return this.routeStore.has(route.routeConfig?.path as string);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    // console.log('CustomRouteReuseStrategy retrieve', route);
    return this.routeStore.get(route.routeConfig?.path as string) as DetachedRouteHandle;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }

}
